body{
  background: #000;
}
.App{
  header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 55px;
    div{
      // border: 1px solid #000;
    }
    .header_title{
      font-weight:bold;
      letter-spacing: 1px;
      color:#d8d8d8;
      font-family: Roboto ;
      font-size: 1rem;
    }
    .option_nav{
      color:#71c7eb;
      font-size: 1rem;
      letter-spacing: 2px;
      font-weight: 500;
      font-family: '微軟正黑體' ;
    }
  }
  .nav{
    color:white;
    font-family: Roboto ;
    font-weight:500;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 55px;
    color:#808080;
    div{
      &.active{
        color:white;
      }
    }

  }
  .list{
    margin: 5px;
    width: 100%;
    
    .item{
      display:grid;
      grid-template-columns:repeat(3, 1fr);
      grid-column-gap:1.5vw;
      grid-row-gap:1.5vw;
      .item_img{
        aspect-ratio: 1/1;
        width: 30vw;
        height: auto;
        min-height: 13vh;
        position: relative;
        border-radius: 8px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &:after{
          content: "";
          width: 1px;
          padding-bottom:100%;
          margin: -1px; 
          z-index: -1;
        }
        img{
          border-radius: 8px;
          width: 100%;
          height: 100%;
          display: block;
        }
        .leftIcons{
          position: absolute;
          bottom: 5%;
          left: 5%;
          svg{
            fill:#cfcfcf;
            opacity: 0.8;
            width: 20px;
            height: 20px;
          }
        }
        .circleCheck{
          position: absolute;
          bottom: 5%;
          right: 5%;
          svg{
            fill:#cfcfcf;
            opacity: 0.8;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
   
  }
  .singleItem{
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    .blackbg{
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.9);
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: 0.2s;
      opacity: 0;
      &.active{
        opacity: 1;
      }
    }
    .itemContent {
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 2;
      width: 700px;
      color:white;
      transition: 0.5s ease;
      transform: translate(-50%,-50%);
      opacity: 0;
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      &.active{
        opacity: 1;
        transform: translate(-50%,-50%);
      }
      img{
        width: 100%;
  
      }
      .dl_btn{
        box-sizing: border-box;
        appearance: none;
        border-radius: 20em;
        color: white;
        background: #08c4a4;
        font-family: Roboto;
        letter-spacing: 1px;
        cursor: pointer;
        display: flex;
        align-self: center;
        font-size: 1rem;
        line-height: 1;
        margin: 20px;
        padding: 1em 2em ;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        outline: 0;
        border: 0;
        margin:  15px auto;
        font-size: .8rem;
        &:hover,
        &:focus {
          color: #fff;
          outline: 0;
        }
      }
    }
  }
}